import React, { ReactElement } from 'react';

import { Paper } from '@material-ui/core';

import CalendarHeader from '../CalendarHeader/CalendarHeader';
import CalendarForm from '../CalendarForm/CalendarForm';

import styles from './ShowboatCalendar.module.css';
import { ShowboatCalendarStyles } from './ShowboatCalendarStyles';
import { ServerHelper } from '../utilities/ServerHelper';
import { Typography } from '@material-ui/core';


interface Props {
  
}

export default function ShowboatCalendar(props: Props): ReactElement {
  
  const classes = ShowboatCalendarStyles();

  console.log("SH", ServerHelper.loginFailReason)

  //Check for failed login
  if (ServerHelper.loginFailReason === "Deleted calendar code.") {

    return (
      <Paper square className={classes.calendarWrapper}>
        <div className={styles.calendarWrapper}>
          <CalendarHeader />

          <Paper className={classes.deadLinkErrorHolder}>
            <Typography variant="body1" className={classes.deadLinkError}>
              This calendar is no longer in use.
            </Typography>
          </Paper>
        </div>
      </Paper>
    )
  } else {
    return (
      <Paper square className={classes.calendarWrapper}>
        <div className={styles.calendarWrapper}>
          <CalendarHeader />
  
          <CalendarForm />
        </div>
      </Paper>
    )
  }
}
