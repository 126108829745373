import { Button, Divider, Link, Paper, Typography } from '@material-ui/core';
import React, { ReactElement, useState } from 'react';
import RegistrationDetails from './RegistrationDetails/RegistrationDetails';
import DateIcon from '@material-ui/icons/Event';
import RegistrationForm from './RegistrationForm/RegistrationForm';
import { ServerHelper } from '../utilities/ServerHelper';

import styles from './RegistrationView.module.css';
import { RegistrationViewStyles } from './RegistrationViewStyles';

interface Props {
  handleChangeDateAndTimeClick: () => any,
  selectedBooking: any,
}

export default function RegistrationView(props: Props): ReactElement {

  const [ formHasBeenCompleted, toggleFormHasBeenCompleted ] = useState(false);

  const [ loginCode, setLoginCode ] = useState("");

  const handleChangeDateAndTimeClick = () => {
    props.handleChangeDateAndTimeClick();
  }

  const handleBackClick = () => {
    props.handleChangeDateAndTimeClick();
  }

  const handleAddToCalendarClick = () => {
    //Formulate call to calendar endpoint

    let replyTo = ServerHelper.replyToEmailAddress;
    let attendeeLoginCode = loginCode;

    window.open(`${ServerHelper.appAPIUrl}/calendar?loginCode=${loginCode}&replyTo=${replyTo}`);

  }

  /* FORM COMPLETE TO SHOW CONFIRMATION */
  const handleFormComplete = (loginCode : string) => {
    setLoginCode(loginCode);
    toggleFormHasBeenCompleted(true);
  }
  /**/

  //Copy intake link
  const handleCopyIntakeLink = () => {
    let dummy = document.createElement('textarea');

    document.body.appendChild(dummy);

    dummy.value = `https://${document.getElementById("intakeLink").innerText}`;
    dummy.select();

    document.execCommand('copy');
    document.body.removeChild(dummy);
  }
  
  const classes = RegistrationViewStyles();
  
  return (
    <React.Fragment>
      <RegistrationDetails formHasBeenCompleted={formHasBeenCompleted} selectedBooking={props.selectedBooking} handleChangeDateAndTimeClick={handleChangeDateAndTimeClick} />

      {formHasBeenCompleted
      ? (
        <Paper className={classes.confirmationHolder}>
          <Typography variant="body1" className={classes.allSetHeader}>
            Confirmed
          </Typography>

          <Typography variant="body1" className={classes.confirmationMessage}>
            Please add us to your calendar by clicking the link below.
          </Typography>

          <Button variant="contained" className={classes.addToCalendarButton} onClick={handleAddToCalendarClick}>
            <DateIcon  className={classes.dateIcon} />
            ADD TO CALENDAR
          </Button>

          <Typography variant="body1" className={classes.eventLinkHeader}>
            Your personal event link:
          </Typography>

          <div className={styles.linkHolder}>
            <Link className={classes.intakeLink} target="_blank" href={`https://go.showboat.live/${loginCode}`}>
              <span id="intakeLink">
                go.showboat.live/{loginCode}
              </span>
            </Link>

            <img src="assets/images/CopyIcon.png" alt="copy icon" onClick={handleCopyIntakeLink} className={styles.copyIcon} />
          </div>

          <Divider className={classes.confirmDivider}></Divider>

          <Typography variant="body1" className={classes.emailMessage}>
            A calendar invite and personal event link has also been sent to your email address.
          </Typography>
        </Paper>
      ) : (
      <Paper className={classes.registrationWrapper}>
        <Typography variant="h1" classes={{ root: classes.registerNowHeader }}>
          Enter your information
        </Typography>
        
        <RegistrationForm handleBackClick={handleBackClick} handleFormComplete={handleFormComplete} />
      </Paper>
      )}

    </React.Fragment>
  )
}
