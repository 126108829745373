import { Link, Paper, Typography } from '@material-ui/core';
import moment, { min } from 'moment-timezone';
import tz from 'moment-timezone';
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import DateIcon from '@material-ui/icons/Event';
import TimeIcon from '@material-ui/icons/Schedule';
import DurationIcon from '@material-ui/icons/Timelapse';
import React, { ReactElement, useEffect, useState } from 'react';
import { ServerHelper } from '../../utilities/ServerHelper';

import styles from './RegistrationDetails.module.css';
import { RegistrationDetailsStyles } from './RegistrationDetailsStyles';

interface Props {
  handleChangeDateAndTimeClick: () => any,
  selectedBooking: any,
  formHasBeenCompleted: boolean,
}

export default function RegistrationDetails(props: Props): ReactElement {

  const [ timezone, setTimezone ] = useState("");
  const [ fromCalendarPage, toggleFromCalendarPage ] = useState(true);

  const [ months, setMonths ] = useState(null);
  const [ days, setDays ] = useState(null);
  const [ hours, setHours ] = useState(null);
  const [ minutes, setMinutes ] = useState(null);

  useEffect(() => {

    //Check local storage to see if we are coming from calendar page
    let fromCalendarPage = localStorage.getItem("fromCalendarPage");
    
    if (fromCalendarPage === "true") {
      console.log('A');
      toggleFromCalendarPage(true);
    } else {
      console.log('B');
      toggleFromCalendarPage(false);
    }

    //Calculate booking duration
    let then = props.selectedBooking.start.time;
    let now = props.selectedBooking.end.time;

    let ms = moment(now).diff(moment(then));
    let d = moment.duration(ms);

    if (d.months() !== 0) {
      setMonths(d.months());
    }

    if (d.days() !== 0) {
      setDays(d.days());
    }

    if (d.hours() !== 0) {
      setHours(d.hours());
    }

    if (d.minutes() !== 0) {
      setMinutes(d.minutes());
    }

  }, []);

  //format duration string
  let durationString;

  //Format plurals
  let daysPlural;
  let hoursPlural;
  let minutesPlural;

  if (days > 1) {
    daysPlural = "days";
  } else {
    daysPlural = "day";
  }

  if (hours > 1) {
    hoursPlural = "hrs";
  } else {
    hoursPlural = "hour";
  }

  if (minutes > 1) {
    minutesPlural = "mins";
  } else {
    minutesPlural = "min"
  }

  if (days !== null && hours === null && minutes === null) {
    durationString = `${days} ${daysPlural}`;
  } else if (days === null && hours !== null && minutes === null) {
    durationString = `${hours} ${hoursPlural}`;
  } else if (days === null && hours === null && minutes !== null) {
    durationString = `${minutes} ${minutesPlural}`;
  } else if (days !== null && hours !== null && minutes === null) {
    durationString = `${days} ${daysPlural}, ${hours} ${hoursPlural}`;
  } else if (days === null && hours !== null && minutes !== null) {
    durationString = `${hours} ${hoursPlural}, ${minutes} ${minutesPlural}`;
  } else if (days !== null && hours !== null && minutes !== null) {
    durationString = `${days} ${daysPlural}, ${hours} ${hoursPlural}, ${minutes} ${minutesPlural}`;
  } else if (days !== null && hours === null && minutes !== null) {
    durationString = `${days} ${daysPlural}, ${minutes} ${minutesPlural}`;
  }
  else {
    durationString = `0 days, 0 hrs, 0 mins`;
  }

  //Add check for months
  if (months !== null) {

    {months > 1 
    ? durationString = `${months} months, ${durationString}`
    : durationString = `${months} month, ${durationString}` }
  }

  const handleChangeDateAndTimeClick = () => {
    props.handleChangeDateAndTimeClick();
  }
  
  const classes = RegistrationDetailsStyles();
  
  return (
    <Paper className={classes.registrationDetailsHolder}>
      <Typography variant="h2" className={classes.bookingDetailsHeader}>
        Booking Details
      </Typography>

      <Typography variant="body1" className={classes.bookingDate}>
        <DateIcon className={classes.dateIcon}></DateIcon>
        {`${moment(props.selectedBooking.start.time).format("MMMM Do")}`}
      </Typography>

      <Typography variant="body1" className={classes.bookingTime}>
        <TimeIcon className={classes.timeIcon}></TimeIcon>
        {`${moment(props.selectedBooking.start.time).format("h a")}   `}
        {new Date(props.selectedBooking.start.time).toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2]}
      </Typography>

      <Typography variant="body1" className={classes.bookingDuration}>
        <DurationIcon className={classes.durationIcon}></DurationIcon>

        {durationString}
      </Typography>

      {!props.formHasBeenCompleted &&
        <Link className={classes.changeLink} onClick={handleChangeDateAndTimeClick}>
          <LeftIcon className={classes.leftIcon} />
          change day/time
        </Link>
      }
      
    </Paper>
  )
}
