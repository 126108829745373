import { Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import React, { ReactElement, useRef, useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import axios from 'axios';
import InputMask from 'react-input-mask';

import styles from './RegistrationForm.module.css';
import { RegistrationFormStyles } from './RegistrationFormStyles';
import { ServerHelper } from '../../utilities/ServerHelper';

interface Props {
  handleFormComplete?: (loginCode: string) => any,
  handleBackClick: () => any,
}

export default function RegistrationForm(props: Props): ReactElement {

  const intakeFieldMap : Map<string, any> = new Map<string, any>();
  const initialErrorMap : Map<string, boolean> = new Map<string, boolean>();

  let usAddressFields = [
    {name: "Address Line 1", visible: true, mandatory: ServerHelper.ApplicationSkin.intakeFields[5].mandatory},
    {name: "Address Line 2", visible: true, mandatory: false},
    {name: "City", visible: true, mandatory: ServerHelper.ApplicationSkin.intakeFields[5].mandatory},
    {name: "State", visible: true, mandatory: ServerHelper.ApplicationSkin.intakeFields[5].mandatory},
    {name: "Zip Code", visible: true, mandatory: ServerHelper.ApplicationSkin.intakeFields[5].mandatory},
  ];
  
  let internationalAddressFields = [
    {name: "Address Line 1", visible: true, mandatory: ServerHelper.ApplicationSkin.intakeFields[5].mandatory},
    {name: "Address Line 2", visible: true, mandatory: false},
    {name: "City", visible: true, mandatory: ServerHelper.ApplicationSkin.intakeFields[5].mandatory},
    {name: "State/Province/Region", visible: true, mandatory: false},
    {name: "Zip/Postal Code", visible: true, mandatory: false},
    { name: "Country", visible: true, mandatory: ServerHelper.ApplicationSkin.intakeFields[5].mandatory}
  ]

  ServerHelper.ApplicationSkin.intakeFields.forEach((intakeField:any)=>{

    if (intakeField.name === "Address" && intakeField.visible === true) {

      if (intakeField.type === "US") {

        usAddressFields.forEach((intakeField: any) => {
          intakeFieldMap.set(intakeField.name, intakeField);
          initialErrorMap.set(intakeField.name, false);
        });

      } else if (intakeField.type === "international") {
        internationalAddressFields.forEach((intakeField : any) => {
          intakeFieldMap.set(intakeField.name, intakeField);
          initialErrorMap.set(intakeField.name, false);
        })
      }
      
    } else {
      intakeFieldMap.set(intakeField.name, intakeField);
      initialErrorMap.set(intakeField.name, false);
    }
    
  });
  
  /**State store */

  const [ submitError, toggleSubmitError ] = useState(false);
  
  const [ firstName, setFirstName ] = useState("");

  const [ lastName, setLastName ] = useState("");

  const [ company, setCompany ] = useState("");
  
  //grabs already-existing value from context if it exists
  const [ emailAddress, setEmailAddress ] = useState("");
  const [ emailAddressError, toggleEmailAddressError ] = useState(false);

  const [ phoneNumber, setPhoneNumber ] = useState("");

  const [ addressLine1, setAddressLine1 ] = useState("");

  const [ addressLine2, setAddressLine2 ] = useState("");

  const [ city, setCity ] = useState("");

  /* US ADDRESSES */
  const [ state, setState ] = useState("");

  const [ zipCode, setZipCode ] = useState("");
  /**/

  /* INTERNATIONAL ADDRESSES */ 
  const [ stateProvinceRegion, setStateProvinceRegion ] = useState("");

  const [ zipPostalCode, setZipPostalCode ] = useState("");
  
  const [ country, setCountry ] = useState("");
  /**/

  const [ formHasBeenSubmitted, setFormHasBeenSubmitted ] = useState(false);
  /**/

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);

  const [ needsDraw, toggleNeedsDraw ] = useState(false);

  const errorMapRef = useRef(initialErrorMap);

  /* Input change handlers */
  const handleFirstNameChange = (e: any) => {
    toggleNeedsDraw(true);
    
    errorMapRef.current = errorMapRef.current.set("First Name", false);

    setFirstName(e.target.value);
  }

  const handleLastNameChange = (e: any) => {
    toggleNeedsDraw(true);
    
    errorMapRef.current = errorMapRef.current.set("Last Name", false);

    setLastName(e.target.value);
  }

  const handleCompanyChange = (e: any) => {  
    toggleNeedsDraw(true);

    errorMapRef.current = errorMapRef.current.set("Company", false);

    setCompany(e.target.value);
  }

  const handleEmailAddressChange = (e: any) => {
    toggleNeedsDraw(true);

    if (isEmail(e.target.value) || !e.target.value.trim().length) {
      toggleEmailAddressError(false);
    }
    //set required error to false
    errorMapRef.current = errorMapRef.current.set("Email", false);

    setEmailAddress(e.target.value);    
  }

  const handlePhoneChange = (e: any) => {
    toggleNeedsDraw(true);

    errorMapRef.current = errorMapRef.current.set("Phone", false);

    setPhoneNumber(e.target.value);
  }

  const handleAddressLine1Change = (e: any) => {
    toggleNeedsDraw(true);

    errorMapRef.current = errorMapRef.current.set("Address Line 1", false);

    setAddressLine1(e.target.value);
  }

  const handleAddressLine2Change = (e: any) => {
    toggleNeedsDraw(true);

    errorMapRef.current = errorMapRef.current.set("Address Line 2", false);

    setAddressLine2(e.target.value);
  }

  const handleCityChange = (e: any) => {
    toggleNeedsDraw(true);

    errorMapRef.current = errorMapRef.current.set("City", false);

    setCity(e.target.value);
  }

  const handleStateChange = (e: any) => {
    toggleNeedsDraw(true);

    errorMapRef.current = errorMapRef.current.set("State", false);

    setState(e.target.value);
  }

  const handleZipCodeChange = (e: any) => {
    toggleNeedsDraw(true);

    errorMapRef.current = errorMapRef.current.set("Zip Code", false);

    setZipCode(e.target.value);
  }

  const handleStateProvinceRegionChange  = (e: any) => {
    toggleNeedsDraw(true);

    errorMapRef.current = errorMapRef.current.set("State/Province/Region", false);

    setStateProvinceRegion(e.target.value);
  }

  const handleZipPostalCodeChange = (e: any) => {
    toggleNeedsDraw(true);

    errorMapRef.current = errorMapRef.current.set("Zip/Postal Code", false);

    setZipPostalCode(e.target.value);
  }

  const handleCountryChange = (e: any) => {
    toggleNeedsDraw(true);

    errorMapRef.current = errorMapRef.current.set("Country", false);

    setCountry(e.target.value);
  }
  /**/
  
  /* Back handler */
  const handleBioFormBack = () => {

    toggleEmailAddressError(false);
    toggleSubmitError(false);
    
    props.handleBackClick();
  }
  /**/

  /* submit handler */
  const handleBioFormSubmit = () => {

    toggleSubmitError(false);

    let formHasError = false ;

    let intakeField : any;
    
    toggleNeedsDraw(false);

    //First name
    intakeField = intakeFieldMap.get("First Name")!;
    if(intakeField && intakeField.visible && intakeField.mandatory){
      let error : boolean = (!firstName.trim().length);
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //Last name
    intakeField = intakeFieldMap.get("Last Name")!;
    if(intakeField && intakeField.visible && intakeField.mandatory){
      let error : boolean = (!lastName.trim().length);
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //company
    intakeField = intakeFieldMap.get("Company")!;
    if (intakeField && intakeField.visible && intakeField.mandatory){
      let error : boolean = (!company.trim().length);
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //email
    intakeField = intakeFieldMap.get("Email")!;
    let emailValidErr = false;
    if (emailAddress.trim().length && !isEmail(emailAddress)) {
      if (intakeField && intakeField.visible) {
        toggleEmailAddressError(true);
        emailValidErr = true;
        formHasError = true;
      }
    }
    if (intakeField && intakeField.visible && intakeField.mandatory){
      let error : boolean = ((!emailAddress.trim().length) || emailValidErr);
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //phone number
    intakeField = intakeFieldMap.get("Phone")!;
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error : boolean = (!phoneNumber.trim().length);
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //address line 1
    intakeField = intakeFieldMap.get("Address Line 1")!;
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error : boolean = (!addressLine1.trim().length);
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //address line 2
    intakeField = intakeFieldMap.get("Address Line 2")!;
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error : boolean = (!addressLine2.trim().length);
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //city
    intakeField = intakeFieldMap.get("City")!;
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error : boolean = (!city.trim().length);
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //state
    intakeField = intakeFieldMap.get("State")!;
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error : boolean = (!state.trim().length);
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //zip code
    intakeField = intakeFieldMap.get("Zip Code")!;
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error : boolean = (!zipCode.trim().length);
      errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    /* INTERNATIONAL SPECIFIC */
      //state/province/region
      intakeField = intakeFieldMap.get("State/Province/Region")!;
      if (intakeField && intakeField.visible && intakeField.mandatory) {
        let error : boolean = (!stateProvinceRegion.trim().length);
        errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
        formHasError = formHasError || error;
      }

      //zip/postal code
      intakeField = intakeFieldMap.get("Zip/Postal Code")!;
      if (intakeField && intakeField.visible && intakeField.mandatory) {
        let error : boolean = (!zipPostalCode.trim().length);
        errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
        formHasError = formHasError || error;
      }

      //country/region
      intakeField = intakeFieldMap.get("Country")!;
      if (intakeField && intakeField.visible && intakeField.mandatory) {
        let error : boolean = (!country.trim().length);
        errorMapRef.current = errorMapRef.current.set(intakeField.name, error);
        formHasError = formHasError || error;
      }
    /**/

    let addressObj;

    if (ServerHelper.ApplicationSkin.intakeFields[5].type === "US") {

      addressObj = {
        addressLine1,
        addressLine2,
        city,
        state,
        zipCode
      };

    } else {
      addressObj = {
        addressLine1,
        addressLine2,
        city,
        stateProvinceRegion,
        zipPostalCode,
        country
      } 
    };

    if (formHasError) {
      setFormHasBeenSubmitted(true);
    } else {
      setFormHasBeenSubmitted(true);
      
      toggleLoadingSpinner(true);

      axios.post("/signup", {
        bookingID: ServerHelper.bookingID,
        first: firstName,
        last: lastName,
        email: emailAddress,
        company: company,
        phone: phoneNumber,
        address: addressObj,
      })
      .then(function(response) {
        toggleLoadingSpinner(false);
        props.handleFormComplete(response.data.loginCode);
      })
      .catch(function(error) {
        toggleLoadingSpinner(false);
        toggleSubmitError(true);
      })
    }
  }
  /**/

  //materialUI classes
  const classes = RegistrationFormStyles();

  let formFields = [];
  let intakeField : any;
  let fieldName : string;

  //First Name field
  fieldName = "First Name";
  intakeField = intakeFieldMap.get(fieldName)!;
  if(intakeField && intakeField.visible){
    formFields.push(
      <TextField 
        id={fieldName}
        inputProps={{maxLength: 50}}
        helperText={intakeField.mandatory ? "Required" : ""}
        label={intakeField.name}
        InputProps={{
          className: classes.input
        }}
        variant="outlined"
        classes={intakeField.mandatory ? { root: classes.bioInput } : { root: classes.bioInputNotRequired }}
        key={intakeField.name}
        onChange={handleFirstNameChange}
        value={firstName}
        error={errorMapRef.current.get(fieldName)} 
      />
    );
  }

  //Last Name field
  fieldName = "Last Name";
  intakeField = intakeFieldMap.get(fieldName)!;
  if(intakeField && intakeField.visible){
    formFields.push(
      <TextField 
        helperText={intakeField.mandatory ? "Required" : ""}
        label={intakeField.name}
        inputProps={{maxLength: 50}}
        variant="outlined"
        InputProps={{
          className: classes.input
        }}
        classes={intakeField.mandatory ? { root: classes.bioInput } : { root: classes.bioInputNotRequired }}
        key={intakeField.name}
        onChange={handleLastNameChange}
        value={lastName}
        error={ errorMapRef.current.get(fieldName) }
      />
    );
  }

  //Company field
  fieldName = "Company";
  intakeField = intakeFieldMap.get(fieldName)!;
  if (intakeField && intakeField.visible) {
    formFields.push(
      <TextField
        helperText={intakeField.mandatory ? "Required" : ""}
        label={intakeField.name}
        inputProps={{maxLength: 50}}
        variant="outlined"
        InputProps={{
          className: classes.input
        }}
        classes={intakeField.mandatory ? { root: classes.bioInput } : { root: classes.bioInputNotRequired }}
        onChange={handleCompanyChange}
        key={intakeField.name}
        value={company}
        error={ errorMapRef.current.get(fieldName)}
      />
    )
  }

  //Email Address field
  fieldName = "Email";
  intakeField = intakeFieldMap.get(fieldName)!;
  if (intakeField && intakeField.visible) {
    formFields.push(
      <TextField
        helperText={
          intakeField.mandatory 
          ? (formHasBeenSubmitted) 
            ? (emailAddressError) 
              ? "Must be a valid email address" 
              : "Required" 
            : "Required"
          : (formHasBeenSubmitted)
            ? (emailAddressError)
              ? "Must be a valid email address" 
              : ""
            : ""
        }
        label={intakeField.name}
        inputProps={{maxLength: 50}}
        variant="outlined"
        InputProps={{
          className: classes.input
        }}
        classes={intakeField.mandatory ? { root: classes.bioInput } : { root: classes.bioInputNotRequired }}
        onChange={handleEmailAddressChange}
        key={intakeField.name}
        value={emailAddress}
        error={errorMapRef.current.get(fieldName) || emailAddressError}
      />
    )
  }

  //telephone number field
  fieldName = "Phone";
  intakeField = intakeFieldMap.get(fieldName)!;
  if (intakeField && intakeField.visible) {
    formFields.push(
      <InputMask
        mask={ServerHelper.ApplicationSkin.intakeFields[5].type === "international" 
        ? "+9 (999) 999-9999"
        : "(999) 999-9999"
        }
        value={phoneNumber}
        onChange={handlePhoneChange}
        maskPlaceholder=" "
        key="input mask"
      >
          {() => <TextField
          helperText={intakeFieldMap.get("Phone")!.mandatory ? "Required" : ""}
          label={"Phone"}
          InputProps={{
            className: classes.input
          }}
          variant="outlined"
          classes={intakeFieldMap.get("Phone")!.mandatory ? { root: classes.bioInput } : { root: classes.bioInputNotRequired }}
          key="Phone"
          error={ errorMapRef.current.get("Phone")}
      />}
      </InputMask>
    )
  }

  //address line 1 field
  fieldName = "Address Line 1";
  intakeField = intakeFieldMap.get(fieldName)!;
  if (intakeField && intakeField.visible) {
    formFields.push(
      <TextField
        helperText={intakeField.mandatory ? "Required" : ""}
        label="Address Line 1"
        inputProps={{ maxLength: 250 }}
        variant="outlined"
        InputProps={{
          className: classes.input
        }}
        classes={intakeField.mandatory ? { root: classes.bioInput } : { root: classes.bioInputNotRequired }}
        onChange={handleAddressLine1Change}
        key="Address Line 1"
        value={addressLine1}
        error={ errorMapRef.current.get(fieldName)}
      />
    )
  }

  //address line 2 field
  fieldName = "Address Line 2";
  intakeField = intakeFieldMap.get(fieldName)!;
  if (intakeField && intakeField.visible) {
    formFields.push(
      <TextField
        helperText={intakeField.mandatory ? "Required" : ""}
        label="Address Line 2"
        inputProps={{ maxLength: 250 }}
        variant="outlined"
        InputProps={{
          className: classes.input
        }}
        classes={intakeField.mandatory ? { root: classes.bioInput } : { root: classes.bioInputNotRequired }}
        onChange={handleAddressLine2Change}
        key="Address Line 2"
        value={addressLine2}
        error={ errorMapRef.current.get(fieldName)}
      />
    )
  }

  //city field
  fieldName = "City";
  intakeField = intakeFieldMap.get(fieldName)!;
  if (intakeField && intakeField.visible) {
    formFields.push(
      <TextField
        helperText={intakeField.mandatory ? "Required" : ""}
        label="City"
        inputProps={{ maxLength: 50 }}
        variant="outlined"
        InputProps={{
          className: classes.input
        }}
        classes={intakeField.mandatory ? { root: classes.bioInput } : { root: classes.bioInputNotRequired }}
        onChange={handleCityChange}
        key="City"
        value={city}
        error={ errorMapRef.current.get(fieldName)}
      />
    )
  }


  //DISPLAY US-SPECIFIC FIELDS
  if (ServerHelper.ApplicationSkin.intakeFields[5].type === "US") {
    //state field
    fieldName = "State";
    intakeField = intakeFieldMap.get(fieldName)!;
    if (intakeField && intakeField.visible) {
      formFields.push(
        <TextField
          helperText={intakeField.mandatory ? "Required" : ""}
          label="State"
          inputProps={{ maxLength: 50 }}
          variant="outlined"
          InputProps={{
            className: classes.input
          }}
          classes={intakeField.mandatory ? { root: classes.bioInput } : { root: classes.bioInputNotRequired }}
          onChange={handleStateChange}
          key="State"
          value={state}
          error={ errorMapRef.current.get(fieldName)}
        />
      )
    }

    //zip code
    fieldName = "Zip Code";
    intakeField = intakeFieldMap.get(fieldName)!;
    if (intakeField && intakeField.visible) {
      formFields.push(
        <TextField
          helperText={intakeField.mandatory ? "Required" : ""}
          label="Zip Code"
          inputProps={{
            maxLength: 10,
          }}
          InputProps={{
            className: classes.input
          }}
          variant="outlined"
          classes={intakeField.mandatory ? { root: classes.bioInput } : { root: classes.bioInputNotRequired }}
          onChange={handleZipCodeChange}
          key="Zip Code"
          value={zipCode}
          error={ errorMapRef.current.get(fieldName)}
        />
      )
    }
  } 
  
  //DISPLAY INTERNATIONAL FIELDS
  else {

    fieldName = "State/Province/Region";
    intakeField = intakeFieldMap.get(fieldName)!;
    if (intakeField && intakeField.visible) {
      formFields.push(
        <TextField
          helperText={intakeField.mandatory ? "Required" : ""}
          label="State/Province/Region"
          inputProps={{
            maxLength: 100,
          }}
          InputProps={{
            className: classes.input
          }}
          variant="outlined"
          classes={intakeField.mandatory ? { root: classes.bioInput } : { root: classes.bioInputNotRequired }}
          onChange={handleStateProvinceRegionChange}
          key="State/Province/Region"
          value={stateProvinceRegion}
          error={ errorMapRef.current.get(fieldName)}
        />
      )
    }

    fieldName = "Zip/Postal Code";
    intakeField = intakeFieldMap.get(fieldName)!;
    if (intakeField && intakeField.visible) {
      formFields.push(
        <TextField
          helperText={intakeField.mandatory ? "Required" : ""}
          label="Zip/Postal Code"
          inputProps={{
            maxLength: 10,
          }}
          InputProps={{
            className: classes.input
          }}
          variant="outlined"
          classes={intakeField.mandatory ? { root: classes.bioInput } : { root: classes.bioInputNotRequired }}
          onChange={handleZipPostalCodeChange}
          key="Zip/Postal Code"
          value={zipPostalCode}
          error={ errorMapRef.current.get(fieldName)}
        />
      )
    }

    fieldName = "Country";
    intakeField = intakeFieldMap.get(fieldName)!;
    if (intakeField && intakeField.visible) {
      formFields.push(
        <TextField
          helperText={intakeField.mandatory ? "Required" : ""}
          label="Country"
          inputProps={{
            maxLength: 100,
          }}
          InputProps={{
            className: classes.input
          }}
          variant="outlined"
          classes={intakeField.mandatory ? { root: classes.bioInput } : { root: classes.bioInputNotRequired }}
          onChange={handleCountryChange}
          key="Country"
          value={country}
          error={ errorMapRef.current.get(fieldName)}
        />
      )
    }

  }

  return (
    <React.Fragment>

      {submitError && 
        <Typography className={classes.error} variant="body1">
          An error occurred submitting your registration. Please try again.
        </Typography>
      }
      <form className={`${styles.bioForm} ${styles.bioFormStandalone}`} onSubmit={handleBioFormSubmit}>
        {formFields}
      </form>

      {loadingSpinner &&
        <CircularProgress className={classes.loadingSpinner} />
      }

      <Button disabled={loadingSpinner} onClick={handleBioFormSubmit} variant="contained" classes={{ root: classes.bioNext }}>SUBMIT</Button>

      <Button disabled={loadingSpinner} onClick={handleBioFormBack} variant="contained" classes={{ root: classes.bioBack }}>BACK</Button>

    </React.Fragment>
    
  )
}

  

