const axios = require('axios');

export class ServerHelper {

    public static OnServerConnectionTimeout: NSM.Delegate = new NSM.Delegate();
    public static loginCode : string;

    //ApplicationSkin object with default values (mainly for dev codes)
    public static ApplicationSkin : any = {
        theme: "light",
        primaryThemeColor: "#FF0092",
        secondaryThemeColor: "#00FF00",
        landingPageThumbnailURL: "assets/images/sb-logo.png",
        eventName: "Event Name",
        eventTime: "August 4th, 2020 - 1PM",
        intakeFields: [
            {name: "First Name", visible: true, mandatory: true},
            {name: "Last Name", visible: true, mandatory: false},
            {name: "Company", visible: true, mandatory: false},
            {name: "Email", visible: true, mandatory: false},
            {name: "Phone", visible: false, mandatory: false},
            {name: "Address", visible: false, mandatory: false, type: "US"}
        ]
    };

    public static LocalAvatarDataManager : any = {};

    public static loginFailReason : string;

    public static startDateTime : string;
    public static endDateTime : string;

    public static deadLoginCode: boolean = false;
    public static errorMsg: string; 
    
    public static replyToEmailAddress: string;

    public static calendar: any;

    public static bookingID: string;
    
    public static appAPIUrl = `https://3788oxr7li.execute-api.us-east-1.amazonaws.com/${process.env.REACT_APP_API_STAGE}`;

    public static async Login() : Promise<any>{

        //Setup axios to call the API
        axios.defaults.baseURL = ServerHelper.appAPIUrl;

        //Make login call  
        ServerHelper.loginCode = ServerHelper.getLoginCode();
        if(ServerHelper.loginCode.length == 0){
            return ServerHelper.getErrorLoginResult("Missing Login Code");
        }

        const params = {
            calendarCode : ServerHelper.loginCode,
            timestamp: new Date().getTime()
        };
          
        try{
            const loginApiResponse = await axios.get('/login', { params });

            if(loginApiResponse && loginApiResponse.data){
                if(loginApiResponse.data.success){
                    let loginResult = loginApiResponse.data as any;                  
                    ServerHelper.storeLoginResultData(loginResult);
                    ServerHelper.bookingID = loginResult.eventID;
                    return loginResult;
                } else if (!loginApiResponse.data.success && loginApiResponse.data.failReason === "Deleted calendar code.") {
                    let loginResult = loginApiResponse.data as any;
                    ServerHelper.storeLoginResultData(loginResult);
                    ServerHelper.loginFailReason = (loginResult.failReason as string);
                    ServerHelper.bookingID = loginResult.eventID;
                    ServerHelper.errorMsg = (loginResult.errorMsg as string);
                    return loginResult;
                } else if (!loginApiResponse.data.success &&
                (loginApiResponse.data.failReason === "NotStarted" || loginApiResponse.data.failReason === "Ended" 
                || loginApiResponse.data.failReason === "CapacityFull" || loginApiResponse.data.failReason === "RegistrationCapacityFull")) {
                    let loginResult = loginApiResponse.data as any;
                    ServerHelper.bookingID = loginResult.eventID;
                    ServerHelper.storeLoginResultData(loginResult);
                    ServerHelper.deadLoginCode = false;
                    ServerHelper.loginFailReason = (loginResult.failReason as string);
                    return loginResult;
                }
                else {
                    return ServerHelper.getErrorLoginResult("Login unsuccesful");
                }

            } else {
                return ServerHelper.getErrorLoginResult("Login result failed");
            }

        } catch (err) {
            return ServerHelper.getErrorLoginResult("Missing Login Code");
        }        

    }

    private static getLoginCode() : string {
        //Get the code the person is attempting to login in with
        let loginCode = ServerHelper.GetQueryParam("login");

        //Check if we got a login code. If not, try to parse it from the URL
        if(!loginCode || loginCode.length === 0 || loginCode === null){
            //loginCode = window.location.pathname.split("/").pop();
            loginCode = window.location.pathname;

            let searchResult = loginCode.search(/^\/[a-zA-Z0-9]*$/);

            if(searchResult < 0  || !loginCode || loginCode.length === 0 || loginCode === null){
                loginCode = "devTest";
            } else {
                loginCode = loginCode.substring(1);
            }

        }

        return loginCode;

    }

    private static GetQueryParam = (paramName : string) => {
        paramName = paramName.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + paramName + '=([^&#]*)');
        var results = regex.exec(window.location.search);
        return (results !== null) ? decodeURIComponent(results[1].replace(/\+/g, ' ')) : null;
    };


    private static getErrorLoginResult(failReason:string) : any {
        let loginResult : any = {
            success : false,
            failReason : failReason,
            role : "",
            registrationData : null,
            bookingName : "",
            startTime : "",
            endTime : "",
            uiSkin : {},
            avatarSkin : {},
            worldSkin : {},
            avatar : {},
            world : {},
            userData: {},
            eventID: "",
            gameServer: "",
            gamePort: 0
           /*  applicationSkin : null */,
           calendar: {}
        }
        return loginResult;
    }

    private static storeLoginResultData(loginResult : any){
        if(loginResult.success || 
            //also set skinnable properties for error states where we need them
            (loginResult.failReason === "NotStarted" || loginResult.failReason === "Ended" 
            || loginResult.failReason === "CapacityFull" || loginResult.failReason === "Deleted calendar code."
            || loginResult.failReason === "RegistrationCapacityFull")){
                

            /* CAlENDAR DATA */
            if (loginResult.calendar) {
                ServerHelper.calendar = loginResult.calendar;
            }
                
            /* USER DATA */

            /* EVENT DATA */
            if(loginResult.bookingName){
                ServerHelper.ApplicationSkin.eventName = loginResult.bookingName;
            }

             /* UI Skin */
             if(loginResult.uiSkin && loginResult.uiSkin.theme){
                ServerHelper.ApplicationSkin.theme = loginResult.uiSkin.theme.value;
             }

             if(loginResult.uiSkin && loginResult.uiSkin.landingPageAssetURL){
                ServerHelper.ApplicationSkin.landingPageGraphicURL = loginResult.uiSkin.landingPageAssetURL;
            }

            if(loginResult.uiSkin && loginResult.uiSkin.landingPageGraphicType){
                ServerHelper.ApplicationSkin.landingPageGraphicType = loginResult.uiSkin.landingPageGraphicType;
            }

            if(loginResult.uiSkin && loginResult.uiSkin.thumbnailLogoURL){
                ServerHelper.ApplicationSkin.landingPageThumbnailURL = loginResult.uiSkin.thumbnailLogoURL.value;
            }

            if (loginResult.uiSkin && loginResult.uiSkin.intakeFields){
                
            }

            if (loginResult.uiSkin && loginResult.uiSkin.primaryThemeColor) {
                ServerHelper.ApplicationSkin.primaryThemeColor = loginResult.uiSkin.primaryColor.value;
            }

            /* Avatar Skin */
            if(loginResult.avatarSkin && loginResult.avatarSkin.avatarColors && loginResult.avatarSkin.avatarColors.type == "ColorPairList"){
                let primaryColors : string[] = [];
                let secondaryColors : string[] = [];
                for(let i=0; i < loginResult.avatarSkin.avatarColors.value.length; ++i){
                    let colorRow : any = loginResult.avatarSkin.avatarColors.value[i];
                    primaryColors.push(colorRow.primary);
                    secondaryColors.push(colorRow.secondary);
                }
                if(primaryColors.length > 0 && primaryColors.length == secondaryColors.length){
                    
                    ServerHelper.ApplicationSkin.primaryAvatarColors = primaryColors;
                    ServerHelper.ApplicationSkin.secondaryAvatarColors = secondaryColors;
                }
            }

            /* WORLD SKIN */
            if(loginResult.worldSkin){
                ServerHelper.ApplicationSkin.worldSkin = loginResult.worldSkin;
            }

            /*MODEL FILES*/
            if(loginResult.world && loginResult.world.modelFile){
                ServerHelper.ApplicationSkin.spaceModelURL = loginResult.world.modelFile;
            }

            if(loginResult.world && loginResult.world.sourceFile && loginResult.world.sourceFile[0]){
                ServerHelper.ApplicationSkin.babylonToolkitBundlePath = loginResult.world.sourceFile[0];
            }

            if(loginResult.avatar && loginResult.avatar.modelFile){
                ServerHelper.ApplicationSkin.avatarModelURL = loginResult.avatar.modelFile;
            }

            if(loginResult.startTime){
                let startDateTime = new Date(loginResult.startTime);

                const day = startDateTime.getDate()+(startDateTime.getDate() % 10 == 1 && startDateTime.getDate() != 11 ? 'st' : (startDateTime.getDate() % 10 == 2 && startDateTime.getDate() != 12 ? 'nd' : (startDateTime.getDate() % 10 == 3 && startDateTime.getDate() != 13 ? 'rd' : 'th'))); 
                const month = startDateTime.toLocaleString('default', { month: 'long' });
                const year = startDateTime.toLocaleString('default', { year: 'numeric' });
                const time = startDateTime.toLocaleString('default', { hour: 'numeric', minute: 'numeric' });

                const dateString = `${month} ${day}, ${year} - ${time}`;
                ServerHelper.ApplicationSkin.eventTime = dateString;

                ServerHelper.startDateTime = loginResult.startTime;
                ServerHelper.endDateTime = loginResult.endTime;

            }            

            if (loginResult.registrationData) {
                 /*** INTAKE DATA  ****/
                 ServerHelper.LocalAvatarDataManager.registrationData = loginResult.registrationData;


                 /*** Local Avatar Data ***/
                
                if (loginResult.registrationData.firstName !== undefined) {
                    ServerHelper.LocalAvatarDataManager.firstName = loginResult.registrationData.firstName;
                }
                if (loginResult.registrationData.lastName !== undefined) {
                    ServerHelper.LocalAvatarDataManager.lastName = loginResult.registrationData.lastName;
                }
                if (loginResult.registrationData.company !== undefined) {
                    ServerHelper.LocalAvatarDataManager.company = loginResult.registrationData.company;
                }

            }

            if(loginResult.eventID){
                ServerHelper.LocalAvatarDataManager.eventID = loginResult.eventID;
            }
            
            if (loginResult.role === 'presenter') {

                //ADD USERDATA PRESENTATION STUFF TO APPLICATION SKIN

                if(loginResult.userData.slides){
                    ServerHelper.ApplicationSkin.slides = loginResult.userData.slides;                        
                }
            }
        }
    }
  }

